<template>
  <div class="list-con">
    <ul v-if="data && data.length" class="list back-fff flex-wrap flex-column justify-between">
      <li class="item flex" v-for="(item,index) in data" :key="index">
        <slot :item="item"></slot>
      </li>
      <li class="item" v-for="(item,index) in 2" :key="'s'+index" style="visibility:hidden;height:0;padding:0">
      </li>
    </ul>
    <el-empty v-if="!this.loading && !data.length" description="暂无数据"></el-empty>
    <Page v-if="$isPc" v-bind="$attrs" v-on="$listeners" />
  </div>
</template>
<script>
import Page from '@/components/Page.vue'
export default {
  name: 'List',
  props: ['data', 'loading'],
  components: {
    Page
  }
}
</script>
<style lang="scss" scoped>
/deep/ .item {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    .item-info {
      .item-title {
        color: #1e90fb;
      }
    }
  }
  & > a,
  .item-info {
    width: 100%;
  }
  .item-info {
    .item-title {
      line-height: 24px;
      font-size: 20px;
    }
    .item-info-text {
      line-height: 24px;
      margin: 30px 0;
    }
  }
}
/deep/ .item-img {
  max-width: 340px;
  height: 100%;
}

@media (max-width: 767px) {
  /deep/ .item {
    width: 94.7%;
    height: 90px;
    padding: 10px;
    .item-info {
      .item-title {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }
      p {
        font-size: 14px;
      }
      .item-info-desc {
        .text-ellipsis {
          width: 50%;
        }
      }
    }
    & > a,
    .item-info {
      height: 100%;
    }
  }
}
</style>