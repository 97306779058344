/** 我的收藏 */
<template>
  <div class="my-collect">
    <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
      <List :data="orders" :loading="loading" :total="pageTotal" @page="changePage">
        <template v-slot="{item}">
          <div :class="['item-info', {'flex-1':$isPc}, 'flex-column', $isPc?'justify-between':'justify-around']">
            <router-link :to="{ path: '/article_details', query: { id: item.id, type: 'policy' } }"
              class="item-title-link">
              <h4 class="item-title text-ellipsis-lines mb-30">
                {{item.title}}
              </h4>
            </router-link>
            <p class="item-info-desc">
              <span class="mr-30">
                收藏时间：{{item.collect_at|filterTime}}
              </span>
              <span class="mr-30">
                阅读量：{{item.read_num}}
              </span>
              <span v-if="$isPc">
                <i class="el-icon-star-on is-active-collect"></i>
                <el-button type="text" @click="delCollect(item.id)">取消收藏</el-button>
              </span>
              <span v-else style="float:right">
                <el-popover placement="bottom" width="80%" trigger="click">
                  <el-button type="text" @click="delCollect(item.id)" style="width:100%">取消收藏</el-button>
                  <i slot="reference" class="el-icon-more"></i>
                </el-popover>
              </span>
            </p>
          </div>
        </template>
      </List>
    </LoadMore>
  </div>

</template>

<script>
import { getCollect } from '@/api/my'
import { collectArticle } from '@/api/article'
import Page from '@/components/Page.vue'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
export default {
  name: 'Collect',
  components: {
    LoadMore,
    List,
    Page
  },
  data () {
    return {
      orders: [],
      orders2: [],
      pageTotal: 0,
      page: 1,
      loading: false,//正在加载
      finished: false, // 加载完成
    }
  },
  mounted () {
    this.onLoadMore()
  },
  methods: {
    async getOrder (page) {
      try {
        let { data, total } = await getCollect(page)
        this.orders = this.$isPc ? data : [...this.orders, ...data]
        this.pageTotal = total
        this.getOrder2(page)
      } catch (error) { }
      this.loading = false
    },
    // 预留第二页
    async getOrder2 (page) {
      let maxPage = Math.ceil(this.pageTotal / 10)
      if (maxPage <= 1 && this.page >= maxPage) {
        this.orders2 = []
        return false
      }
      try {
        let { data, total } = await getCollect(page + 1)
        this.orders2 = data
        this.pageTotal = total
      } catch (error) { }
    },
    // 删除收藏
    async delCollect (id) {
      try {
        if (!this.$store.getters.userToken) return this.$message.warning('请先登录');
        let { code, msg } = await collectArticle(id)
        if (code == 200) {
          this.$message.success(msg);
          // 当前页删除一项后，从预留数组中拿取第一项
          let ind = this.orders.findIndex(i => i.id == id)
          this.orders.splice(ind, 1);
          if (this.orders2.length) {
            this.orders.push(this.orders2[0])
            this.orders2.splice(0, 1)
            if (!this.orders2.length) {
              this.getOrder2(this.page)
            }
          }
        } else {
          this.$message.error(msg);
        }
      } catch (error) { }
    },
    // 切换分页
    changePage (page) {
      this.orders = []
      this.page = page
      this.getOrder(page)
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.getOrder(this.page)
      done && done();
      if (this.orders.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.page += 1
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .item {
  &:hover {
    .item-info {
      .item-title {
        color: #000;
      }
    }
  }
  .item-title-link:hover {
    .item-title {
      color: #1e90fb;
    }
  }
}
/deep/ .el-popover {
  max-width: 70px;
}
</style>