// 个人中心
import request from "@/utils/request.js"

// 获取订单
export const getOrder = (page) => {
  return request({
    method: 'get',
    url: '/order',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}

// 删除订单
export const delOrder = (order_id) => {
  return request({
    method: 'get',
    url: '/delorder',
    headers: {
      token: true
    },
    params: {
      order_id
    }
  })
}

// 我的收藏
export const getCollect = (page) => {
  return request({
    method: 'get',
    url: '/userarticlecollect',
    headers: {
      token: true
    },
    params: {
      page
    }
  })
}