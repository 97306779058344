<template>
  <div v-if="pageCount>1" class="page justify-center">
    <el-button class="zpage-prev" :disabled="page===1" @click="page=1">首页</el-button>
    <el-button class="zpage-prev" icon="el-icon-arrow-left" :disabled="page===1" @click="pagePrev"></el-button>
    <ul class="page-list flex">
      <li class="page-item" v-for="(item,index) in pageCounts" :key="index">
        <el-button :class="{'active':page===item}" @click="page=item">{{item}}</el-button>
      </li>
    </ul>
    <el-button class="page-next" icon="el-icon-arrow-right" :disabled="page>=pageCount" @click="pageNext"></el-button>
    <el-button class="page-next" :disabled="page>=pageCount" @click="page=pageCount">尾页
    </el-button>
  </div>
</template>
<script>
export default {
  name: "Page",
  props: {
    // 总条数
    total: {
      typeof: Number | String,
    },
    // 每页显示条目个数
    pageSize: {
      typeof: Number | String,
      default: 10,
    },
    // 重置分页
    resetPage: {
      typeof: Boolean,
      default: false
    }
  },
  data () {
    return {
      pageCount: 0, // 总页数
      pageCounts: [], //页码组 当前页码几到几，例：1-5
      pagerCount: 5,//页码按钮的数量
      page: 1,//当前页码
    }
  },
  mounted () {
    this.setPageNum(this.total)
  },
  watch: {
    total (newVal) {
      this.setPageNum(newVal)
    },
    resetPage (newVal) {
      this.page = 1
    },
    // 页码切换
    page (newVal, oldVal) {
      this.$emit('page', newVal)

      // 点击首页/尾页逻辑
      if (newVal == 1) {
        if (this.pageCount <= this.pagerCount) return false
        this.pageCounts = []
        for (let i = 0; i < this.pagerCount; i++) {
          this.pageCounts.push(i + 1)
        }
      } else if (newVal == this.pageCount) {
        if (this.pageCount <= this.pagerCount) return false
        this.pageCounts = []
        // 最大页数放进页码组
        this.pageCounts.push(this.pageCount)
        for (let i = 0; i < this.pagerCount - 1; i++) {
          let lastPage = this.pageCounts[0]
          this.pageCounts.unshift(lastPage - 1)
        }
      }

      let ind = this.pageCounts.findIndex(item => item == newVal)
      let speed = Math.abs(Math.ceil(ind - (this.pagerCount / 2)))
      for (let i = 0; i < speed; i++) {
        // 当前最大页码
        let lastPage = this.pageCounts[this.pageCounts.length - 1]
        // 当前最小页码
        let firstPage = this.pageCounts[0]
        if (newVal > oldVal) {
          if (lastPage >= this.pageCount) return false
          if (ind >= (this.pagerCount / 2)) {
            this.pageCounts.shift()
            this.pageCounts.push(lastPage + 1)
          }
        } else {
          if (firstPage == 1) return false
          if ((ind + 1) <= (this.pagerCount / 2)) {
            this.pageCounts.unshift(firstPage - 1)
            this.pageCounts.pop()
          }
        }
      }
    }
  },
  methods: {
    setPageNum () {
      this.pageCount = Math.ceil(this.total / this.pageSize)
      this.pageCounts = []
      for (let i = 0; i < this.pageCount; i++) {
        if (i < this.pagerCount) {
          this.pageCounts.push(i + 1)
        }
      }
    },
    // 上一页
    pagePrev () {
      if (this.page > 1) {
        this.page -= 1
      }
    },
    // 下一页
    pageNext () {
      if (this.page < this.pageCount) {
        this.page += 1
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.page {
  margin: 50px 0;
}
.el-button {
  padding: 10px 20px;
  text-align: center;
  border: none;
  background-color: #eff3fa;
  &.is-disabled,
  &.is-disabled :hover {
    background-color: #eff3fa !important;
  }
  &:hover {
    background-color: #5083d1;
    color: #fff;
  }
  &:focus {
    background-color: #eff3fa;
    color: #606266;
  }
  &.active {
    background-color: #2b62b2;
    color: #fff;
  }
}
.page-item {
  margin: 0 10px;
}
.zpage-prev {
  margin-right: 10px;
}
.page-next {
  margin-left: 10px;
}
</style>